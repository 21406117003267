module.exports = [{
      plugin: require('/Users/karmamocro/projects/ziperr/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/zipperlogo.png"},
    },{
      plugin: require('/Users/karmamocro/projects/ziperr/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":""},
    },{
      plugin: require('/Users/karmamocro/projects/ziperr/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-59208032-4","head":false,"pageTransitionDelay":0,"cookieDomain":"zipperr.nl"},
    },{
      plugin: require('/Users/karmamocro/projects/ziperr/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
