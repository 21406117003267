// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/karmamocro/projects/ziperr/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coaching-js": () => import("/Users/karmamocro/projects/ziperr/src/pages/coaching.js" /* webpackChunkName: "component---src-pages-coaching-js" */),
  "component---src-pages-contact-js": () => import("/Users/karmamocro/projects/ziperr/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-corona-js": () => import("/Users/karmamocro/projects/ziperr/src/pages/corona.js" /* webpackChunkName: "component---src-pages-corona-js" */),
  "component---src-pages-index-js": () => import("/Users/karmamocro/projects/ziperr/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-opleidingen-js": () => import("/Users/karmamocro/projects/ziperr/src/pages/opleidingen.js" /* webpackChunkName: "component---src-pages-opleidingen-js" */),
  "component---src-pages-opleidingen-coachopleiding-js": () => import("/Users/karmamocro/projects/ziperr/src/pages/opleidingen/coachopleiding.js" /* webpackChunkName: "component---src-pages-opleidingen-coachopleiding-js" */),
  "component---src-pages-opleidingen-enneagram-js": () => import("/Users/karmamocro/projects/ziperr/src/pages/opleidingen/enneagram.js" /* webpackChunkName: "component---src-pages-opleidingen-enneagram-js" */),
  "component---src-pages-opleidingen-reattachcoachopleiding-js": () => import("/Users/karmamocro/projects/ziperr/src/pages/opleidingen/reattachcoachopleiding.js" /* webpackChunkName: "component---src-pages-opleidingen-reattachcoachopleiding-js" */),
  "component---src-pages-opleidingen-zippercaseopleiding-js": () => import("/Users/karmamocro/projects/ziperr/src/pages/opleidingen/zippercaseopleiding.js" /* webpackChunkName: "component---src-pages-opleidingen-zippercaseopleiding-js" */),
  "component---src-pages-organisatieadvies-js": () => import("/Users/karmamocro/projects/ziperr/src/pages/organisatieadvies.js" /* webpackChunkName: "component---src-pages-organisatieadvies-js" */),
  "component---src-pages-organisatieontwikkeling-js": () => import("/Users/karmamocro/projects/ziperr/src/pages/organisatieontwikkeling.js" /* webpackChunkName: "component---src-pages-organisatieontwikkeling-js" */),
  "component---src-pages-organisatiestructuur-js": () => import("/Users/karmamocro/projects/ziperr/src/pages/organisatiestructuur.js" /* webpackChunkName: "component---src-pages-organisatiestructuur-js" */),
  "component---src-pages-relatie-js": () => import("/Users/karmamocro/projects/ziperr/src/pages/relatie.js" /* webpackChunkName: "component---src-pages-relatie-js" */),
  "component---src-pages-teamontwikkeling-js": () => import("/Users/karmamocro/projects/ziperr/src/pages/teamontwikkeling.js" /* webpackChunkName: "component---src-pages-teamontwikkeling-js" */),
  "component---src-pages-traject-js": () => import("/Users/karmamocro/projects/ziperr/src/pages/traject.js" /* webpackChunkName: "component---src-pages-traject-js" */),
  "component---src-pages-verzuimbegeleiding-js": () => import("/Users/karmamocro/projects/ziperr/src/pages/verzuimbegeleiding.js" /* webpackChunkName: "component---src-pages-verzuimbegeleiding-js" */)
}

